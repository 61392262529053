<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          <v-select v-model="isActiveFilter" class="isActiveFilter" :options="statusOptions" :clearable="false" :reduce="val => val.value"/>
          <strong class="mb-0 ml-50">Toplam Çalışan: {{ employeeCount }}</strong>
        </b-col>
        <b-col cols="12" md="7">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
            <b-button-toolbar justify>
              <b-button-group>
                <b-button @click="reFetchData" variant="outline-primary" size="md">
                  <feather-icon icon="RefreshCcwIcon" size="18"/>
                  <span class="align-middle"></span>
                </b-button>
                <b-dropdown text="Toplu İşlemler" variant="outline-primary" size="md">
                  <b-dropdown-item :href="`/form/leave/add?bulk=true`">
                    <feather-icon icon="SunsetIcon" size="18" class="mr-1"/>
                    <span class="align-middle">İzin Formu</span>
                  </b-dropdown-item>
                  <b-dropdown-item :href="`/form/advance/add?bulk=true`">
                    <feather-icon icon="CreditCardIcon" size="18" class="mr-1"/>
                    <span class="align-middle">Avans Formu</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown text="Excel" variant="outline-primary" size="md">
                  <b-dropdown-item @click="exportEmployee">
                    <feather-icon icon="DownloadIcon" size="18" class="mr-1"/>
                    <span class="align-middle">Çalışan Listesi</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportLeave">
                    <feather-icon icon="DownloadIcon" size="18" class="mr-1"/>
                    <span class="align-middle">İzin Hakedişleri</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-button :to="{ name: 'organization-employee-add' }" variant="primary" size="md">
                  <feather-icon icon="PlusCircleIcon" size="18"/>
                  <span class="align-middle"></span>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'organization-employee-edit'"></id-column-table>
      </template>

      <template #cell(profilePicture)="data">
        <b-avatar rounded :src="getApiFile(data.value)" :text="avatarText(data.item.firstName + ' ' + data.item.lastName)" :variant="`light-primary`" size="35px" v-bind:style="{backgroundColor: randomColor(), color: '#ffffff'}"/>
      </template>

      <template #cell(firstName)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(lastName)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(corporationName)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(workplaceName)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(organizationName)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(positionName)="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(isActive)="data">
        <active-column-table :data="data.value"></active-column-table>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item :to="{ name: 'organization-employee-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Değiştir</span>
            </b-dropdown-item>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>
    <Preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></Preview>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
  ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/organization/employee/Preview.vue";
import {avatarText, getApiFile, randomColor} from "@core/utils/filter";
import Vue from "vue";

export default {
  components: {
    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
    DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable, Preview,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      employeeCount,
      isActiveFilter,

      fetchList,
      reFetchData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const exportEmployee = () => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Excele aktarım yapılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Aktar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/export').then(response => {
            if (response.data.success) {
              window.open(getApiFile(response.data.data), '_blank')
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const exportLeave = () => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Excele aktarım yapılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Aktar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/exportLeave').then(response => {
            if (response.data.success) {
              window.open(getApiFile(response.data.data), '_blank')
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const statusOptions = [
      {label: 'Tüm Kayıtlar', value: null},
      {label: 'Sadece Aktif Kayıtlar', value: true},
      {label: 'Sadece Pasif Kayıtlar', value: false},
    ]

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isPreviewModal,
      dataPreviewRecord,
      employeeCount,
      isActiveFilter,

      statusOptions,

      fetchList,
      reFetchData,
      previewRecord,
      avatarText,
      getApiFile,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      randomColor,
      exportEmployee,
      exportLeave,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.b-dropdown {
  position: relative;
  z-index: 100;
}

.isActiveFilter {
  min-width: 40%;
}
</style>